<template lang="pug">
  div
    TheHeader
    transition(name="fade", mode="out-in")
      keep-alive
        router-view
</template>

<script>
import TheHeader from '@/components/TheHeader';

export default {
  components: { TheHeader }
};
</script>
